import React, {useState, useEffect} from 'react';
import {getAllEquipments, deleteEquipment, updateEquipmentsFromExcel} from '../../config/services/commercialService';
import {Alert, Button, Modal, Spinner} from 'react-bootstrap';
import AddEditModal from './AddEditModal';
import {toast} from 'react-toastify';
import Pagination from "react-js-pagination";
import {Link} from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import Dropzone from "react-dropzone";
import { GymEquipmentTemplateLink } from './Constants';

const itemsCountPerPage = 10;
const columnDetails = [
    {fieldName: "S.No."},
    {fieldName: "Model"},
    {fieldName: "SKU Code"},
    {fieldName: "Brand"},
    {fieldName: "Product type"},
    {fieldName: ""}
];

const EquipmentModels = () => {
    const [equipmentsList, setEquipmentsList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isModalToEdit, setIsModalToEdit] = useState(false);
    const [model, setModel] = useState("");
    const [skuCode, setSkuCode] = useState("");
    const [brand, setBrand] = useState("");
    const [vertical, setVertical] = useState("");
    const [eqpId, setEqpId] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [totalEqps, setTotalEqps] = useState(0);


    const [importExcelPopup, setImportExcelPopup] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [validationSuccess, setValidationSuccess] = useState('');
    const [badEntryErrors, setBadEntryErrors] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    const [requestsExcelSheet, setRequestsExcelSheet] = useState('')

    const navigate = useHistory(); // useNavigate hook for navigation
    const handleButtonClick = () => {
        navigate.push('/verticals');  // Navigate to the desired route on button click
    };

    useEffect(() => {
        fetchEquipmentsList(pageNumber);
    }, []);

    const fetchEquipmentsList = (pageNo) => {
        const params = {pageNo: pageNo, pageSize: itemsCountPerPage};
        getAllEquipments({params})
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    data.result && data.result.data && setEquipmentsList(data.result.data);
                    data.result.pagination && setTotalEqps(data.result.pagination.totalRecords);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const deleteEquipmentFn = (id) => {
        const params = {equipmentId: id};
        deleteEquipment(params)
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    fetchEquipmentsList(pageNumber);
                    toast.success(data.message);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const handlePageChange = (pageNo) => {
        setPageNumber(pageNo);
        fetchEquipmentsList(pageNo);
    };

    const hideModal = () => setShowModal(false);

    const openEqpModelFn = (toEdit, equipment) => {
        setShowModal(true);
        setIsModalToEdit(toEdit);
        setModel(equipment.model || "");
        setSkuCode(equipment.skuCode || "");
        setBrand(equipment.brand || "");
        setVertical(toEdit ? {
            label: equipment.vertical ? equipment.vertical.verticalName : "",
            value: equipment.vertical ? equipment.vertical._id : ""
        } : "");
        setEqpId(equipment._id || "");
    };

    const importHandler = () => {
        setShowModal(false)
        setImportExcelPopup(true);
        setValidationError('');
        setValidationSuccess('')
    }

    const closeModal = () => {
        setImportExcelPopup(false);
        setBadEntryErrors([]);
        setValidationSuccess('')
        setIsUploading(false)
    }


    const handleFileDrop = (accepted, rejected) => {
        if (accepted && accepted.length) {
            setRequestsExcelSheet(accepted[0]);
            setValidationError('');
            setValidationSuccess('')
        }

        else if (rejected && rejected.length) {
            setValidationSuccess('');
            setRequestsExcelSheet('');
        }
    }

    const submitValidateExcelUpload = (e) => {
        e.preventDefault();

        if (!requestsExcelSheet) {
            setValidationError('Please select a file!');
        }else {
            let params = new FormData()
            params.append('equipmenstData', requestsExcelSheet);
            setValidationError('');
            setIsUploading(true);

            updateEquipmentsFromExcel(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode === 1) {
                        setValidationSuccess(response.responseData.message);
                        toast.success(response.responseData.message);
                        fetchEquipmentsList(pageNumber);
                    } else if (response.statusCode === 0) {
                        setValidationError(response.error.errorMessage);
                        toast.error(response.error.errorMessage);
                    }
                    setIsUploading(false);
                    setImportExcelPopup(false);
                })
        }
    }

    const handleDownloadTemplateClick = () => {
        window.open(GymEquipmentTemplateLink, '_blank'); // Opens in a new tab
    };

    return (
        <div className="main-wrapper">
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                <li className="breadcrumb-item">Equipment Models</li>
            </ol>


            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-6">
                    </div>
                    <div className="col-md-6 list-inline-item text-right">
                        <Button className="primary mr-5" type="primary" onClick={handleButtonClick}>Verticals</Button>

                        <Button type="primary" onClick={() => openEqpModelFn(false, {})} className="mr-2">+ Equipment
                            Model</Button>
                        <Button className="primary" type="primary" onClick={() => {
                            importHandler()
                        }}>Import</Button>
                    </div>

                </div>
            </div>

            <div className="table-responsive mb-4 mt-3" style={{minHeight: '100px'}}>
                <table className="table table-bordered table-striped text-center">
                    <thead>
                    <tr>
                        {columnDetails.map((item, i) =>
                            <th scope="col" key={i}>{item.fieldName}</th>
                        )}
                    </tr>
                    </thead>

                    <tbody>
                    {equipmentsList.map((eqp, index) => {
                        return (
                            <tr key={eqp._id}>
                                <td className="cm_no_wrap">{itemsCountPerPage * (pageNumber - 1) + (index + 1)}</td>
                                <td className="cm_no_wrap">{eqp.model}</td>
                                <td className="cm_no_wrap">{eqp.skuCode}</td>
                                <td className="cm_no_wrap">{eqp.brand}</td>
                                <td className="cm_no_wrap">{eqp.vertical ? eqp.vertical.verticalName : ""}</td>
                                <td className="cm_no_wrap">
                                    <Button className='m-1' variant="outline-info"
                                            onClick={() => openEqpModelFn(true, eqp)}> <i className="fa fa-pencil"/>
                                    </Button>
                                    <Button className='m-1' variant="outline-danger"
                                            onClick={() => deleteEquipmentFn(eqp._id)}> <i className="fa fa-trash"/>
                                    </Button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>

                <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalEqps}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                />
            </div>

            {showModal &&
                <AddEditModal
                    showModal={showModal}
                    isModalToEdit={isModalToEdit}
                    hideModal={hideModal}
                    fetchEquipmentsList={fetchEquipmentsList}
                    pageNo={pageNumber}
                    model={model}
                    skuCode={skuCode}
                    brand={brand}
                    vertical={vertical}
                    eqpId={eqpId}
                />
            }


            <Modal show={importExcelPopup} onHide={closeModal} backdrop="static" centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h6>Upload File!</h6></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Dropzone multiple={false}
                              onDrop={(acceptedFiles, rejectedFiles) => handleFileDrop(acceptedFiles, rejectedFiles)}
                              accept='.xlsx, .xls, .csv'>
                        {({getRootProps, getInputProps, isDragActive, acceptedFiles}) => (
                            <section>
                                <div {...getRootProps()} className="dropzone text-center"><input {...getInputProps()} />
                                    <i className="fa fa-cloud-upload"/>
                                    {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ?
                                        <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                </div>
                            </section>
                        )}
                    </Dropzone>

                    <div className="row col-md-4">
                        <Button variant="info"  size="sm" onClick={handleDownloadTemplateClick}>Download Template</Button>
                    </div>
                </Modal.Body>


                {validationSuccess && <Alert variant="success"> {validationSuccess} </Alert>}
                {validationError && <Alert variant="danger"> {validationError} </Alert>}
                {badEntryErrors.length > 0 && <Alert variant="danger">
                    {badEntryErrors.map(err => {
                        return (
                            <>
                                <span>Row: {err.rowNumber}, &nbsp;&nbsp; </span>
                            </>
                        )
                    })}
                </Alert>}


                <Modal.Footer>
                    <Button variant="danger" onClick={closeModal} size="sm">Cancel</Button>
                    <Button variant="success" onClick={submitValidateExcelUpload}
                            disabled={validationError || isUploading} size="sm">
                        {isUploading ? <><Spinner animation="border" variant="warning"
                                                  size="sm"/> Uploading..</> : 'Upload'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default EquipmentModels;