import React, {useState, useEffect} from 'react';
import {toast} from 'react-toastify';
import {addVertical} from '../../config/services/commercialService';
import {Button, Modal, Form, Row, FormGroup, FormLabel} from 'react-bootstrap';
import {Input} from "antd";
import _ from "lodash";

const AddVertical = (props) => {

    const {showModal, hideModal, fetchSpareParts, pageNo} = props;
    const [verticalID, setVerticalID] = useState("");
    const [verticalName, setVerticalName] = useState("");


    const handleValidation = () => {
        if (_.isNil(verticalName) || _.isEmpty(verticalName)) {
            toast.error("Vertical name can't be empty");
            return false;
        }
        return true;
    };

    const formSubmission = (event) => {
        event.preventDefault();
        const validationFlag = handleValidation();
        if (validationFlag) {
            const params = {
                verticalName: verticalName
            };

            addVertical(params)
                .then(res => {
                    const response = res.data;

                    if (response.statusCode === 1) {
                        const data = response.responseData;
                        fetchSpareParts(pageNo);
                        toast.success(data.message);
                        hideModal();
                    } else if (response.statusCode === 0) {
                        toast.error(response.error.errorMessage)
                    }
                })
                .catch((error) => {
                    console.log('error', error);
                })
        }
    };

    return (<div className="main-wrapper">
            <Modal show={showModal} onHide={hideModal} centered size="xl" backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>Add Vertical</Modal.Title>
                </Modal.Header>

                <Modal.Body className="modal-body-height">
                    <Form onSubmit={(e) => formSubmission(e)}>
                        <div className='row' style={{marginTop: "30px"}}>

                            <FormGroup className='col-lg-6 col-xl-6'>
                                <FormLabel>Enter Vertical Name</FormLabel>
                                <Input
                                    type="text"
                                    onChange={(e) => {
                                        setVerticalID(e.target.value);
                                        setVerticalName(e.target.value);
                                    }}
                                    value={verticalID}
                                    placeholder="Vertical Name"
                                />
                            </FormGroup>
                        </div>

                        <Row className="justify-content-center" style={{marginTop: "30px"}}>
                            <Button variant="danger" onClick={hideModal}>Cancel</Button>
                            <Button className="ml-2" variant="success" type="submit">Submit</Button>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>);
};

export default AddVertical;