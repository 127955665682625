import React, {useState, useEffect} from 'react';
import {getAllVerticals, enableVertical} from '../../config/services/commercialService';
import {toast} from 'react-toastify';
import {Button} from 'react-bootstrap';
import Pagination from "react-js-pagination";
import EditVertical from './EditVertical';
import AddVertical from './AddVertical';
import {Link} from "react-router-dom";
import HOC from "../../HOC";

const itemsCountPerPage = 10;
const columnDetails = [{fieldName: "Sr.No."}, {fieldName: "Vertical Name"}, {fieldName: "Action"},];


const Verticals = () => {

    const [spareVerticalsList, setSpareVerticalsList] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalSpareParts, setTotalSpareParts] = useState(0);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [sparePartsToEdit, setSparePartsToEdit] = useState();
    const [sparePartsToAdd, setSparePartsToAdd] = useState(false);

    useEffect(() => {
        fetchAllVerticals(pageNumber);
    }, []);


    const fetchAllVerticals = (pageNo) => {
        const params = {pageNo: pageNo, pageSize: itemsCountPerPage};
        getAllVerticals({params})
            .then(resp => {
                const response = resp.data;
                if (response.statusCode === 1) {
                    const dataObj = response.responseData;
                    dataObj.result && setSpareVerticalsList(dataObj.result);
                    dataObj.result && dataObj.result.pagination && setTotalSpareParts(dataObj.result.pagination.totalRecords);
                } else if (response.statusCode === 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const handlePageChange = (pageNo) => {
        setPageNumber(pageNo);
        fetchAllVerticals(pageNo);
    };

    const openEditModal = (spareParts) => {
        setShowEditModal(true);
        setSparePartsToEdit(spareParts);
    };

    const openAddModal = (spareParts) => {
        setShowAddModal(true);
        setSparePartsToAdd(spareParts);
    };

    const hideEditModal = () => setShowEditModal(false);
    const hideAddModal = () => setShowAddModal(false);

    const handleChangeStatus = (id, isEnabled) => {
        let obj = {
            verticalId: id, isEnable: !isEnabled
        }

        enableVertical(obj)
            .then((res) => {
                fetchAllVerticals(pageNumber)
                if (res.data.statusCode === 1) {
                    toast.success(res.data.responseData.message)
                } else if (res.data.statusCode === 1) {
                    toast.error(res.data.error.errorMessage)
                }
            })
    }

    return (

        <HOC>
            <div className={"body-container-wrapper"}>
                <div className={"body-container"}>
                    <ol className="breadcrumb" style={{display: ""}}>
                        <li className="breadcrumb-item">Gym Services</li>
                        <li className="breadcrumb-item"><Link to="/equipmentModels">Equipments Models</Link></li>
                        <li className="breadcrumb-item">Verticals</li>
                    </ol>


                    <div className="users_header" style={{display: ""}}>
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col-md-6">
                                    <h4 className="cm_page_heading mt-1 mb-0">Verticals</h4>
                                </div>

                                <div className="col-md-6">
                                    <div className="add-gym-button">
                                        <Button type="primary" onClick={() => openAddModal(setSparePartsToAdd)}>Add
                                            Vertical</Button>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                    <div className="table-responsive mb-4" style={{minHeight: '100px'}}>
                        <table className="table table-bordered table-striped text-center">
                            <thead>
                            <tr>
                                {columnDetails.map((item, i) => <th scope="col" key={i}>{item.fieldName}</th>)}
                            </tr>
                            </thead>

                            <tbody>
                            {spareVerticalsList.map((spareParts, index) => {

                                return (<tr key={spareParts._id}>
                                    <td className="cm_no_wrap">{index + 1}</td>
                                    <td className="cm_no_wrap">{spareParts.verticalName}</td>

                                    <td className="cm_no_wrap">
                                        <Button className='m-1' variant="outline-info"
                                                onClick={() => openEditModal(spareParts)}> <i
                                            className="fa fa-pencil"/>
                                        </Button>

                                        <span
                                            className={`cm_ckeckbox_wrapper ${spareParts.isEnabled === true ? 'cm_active' : 'cm_inactive'}`}
                                            onClick={() => handleChangeStatus(spareParts._id, spareParts.isEnabled)}>
                                    <span className="cm_ckeckbox_btn"></span>
                                    </span>


                                    </td>
                                </tr>)
                            })}
                            </tbody>
                        </table>

                        <Pagination
                            activePage={pageNumber}
                            itemsCountPerPage={itemsCountPerPage}
                            totalItemsCount={totalSpareParts}
                            pageRangeDisplayed={3}
                            onChange={handlePageChange}
                        />
                    </div>

                    {showEditModal && <EditVertical
                        showModal={showEditModal}
                        hideModal={hideEditModal}
                        sparePartsToEdit={sparePartsToEdit}
                        fetchSpareParts={fetchAllVerticals}
                        pageNo={pageNumber}
                    />}

                    {showAddModal && <AddVertical
                        showModal={showAddModal}
                        hideModal={hideAddModal}
                        spareParts={sparePartsToAdd}
                        fetchSpareParts={fetchAllVerticals}
                        pageNo={pageNumber}
                    />}
                </div>
            </div>

        </HOC>);
};

export default Verticals;