import React, { useState, useEffect } from 'react';
import {
    getGymDetails,
    getGymEquipments,
    deleteGymEquipment,
    addEquipmentsFromExcelToGym
} from '../../config/services/commercialService';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment';
import {Alert, Button, Modal, Spinner} from 'react-bootstrap';
import AddEquipmentToGymModal from './AddEquipmentToGymModal';
import RequestInstallationModal from './RequestInstallationModal';
import RequestSentModal from './RequestSentModal';
import EditGymEquipmentModal from './EditGymEquipmentModal';
import Pagination from "react-js-pagination";
import { useLocation } from "react-router-dom";
import Dropzone from "react-dropzone";
import {AddEquipmentTemplateLink} from "./Constants";

const itemsCountPerPage = 10;
const columnDetails = [
    { fieldName: "S.No." },
    { fieldName: "Serial Number" },
    { fieldName: "Model" },
    { fieldName: "Brand" },
    { fieldName: "Product type" },
    { fieldName: "Purchase Date" },
    { fieldName: "Delivery Date" },
    { fieldName: "Installation Date" },
    { fieldName: "Warranty Expiry" },
    { fieldName: "Warranty Renew Date" },
    { fieldName: "Installation Status" },
    { fieldName: "" }
];

const GymDetails = () => {
    const location = useLocation();
    const [gymDetails, setGymDetails] = useState("");
    const [gymEquipmentsList, setGymEquipmentsList] = useState([]);
    const [showAddEquipmentToGymModal, setShowAddEquipmentToGymModal] = useState(false);
    const [showRequestInstallationModal, setShowRequestInstallationModal] = useState(false);
    const [showRequestSentModal, setShowRequestSentModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [selectedEquipmentLogisticsId, setSelectedEquipmentLogisticsId] = useState();
    const [gymEqpToEdit, setGymEqpToEdit] = useState();
    const [pageNumber, setPageNumber] = useState(1);
    const [totalGymEqps, setTotalGymEqps] = useState(0);
    const { id } = useParams();

    const [importExcelPopup, setImportExcelPopup] = useState(false);
    const [validationError, setValidationError] = useState('');
    const [validationSuccess, setValidationSuccess] = useState('');
    const [badEntryErrors, setBadEntryErrors] = useState([])
    const [isUploading, setIsUploading] = useState(false)
    const [requestsExcelSheet, setRequestsExcelSheet] = useState('')


    useEffect(() => {
        fetchGymDetails();
        fetchGymEquipments(pageNumber);
    }, []);

    const fetchGymDetails = () => {
        const params = { id: id };
        getGymDetails({params})
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    data.result && setGymDetails(data.result);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const fetchGymEquipments = (pageNo) => {
        const params = { gymId: id, pageNo: pageNo, pageSize: itemsCountPerPage };
        getGymEquipments({params})
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    data.result && setGymEquipmentsList(data.result);
                    data.pagination && setTotalGymEqps(data.pagination.totalRecords);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };

    const deleteGymEquipmentFn = (gymEqp) => {
        const params = {gymEquipmentId: gymEqp._id};

        deleteGymEquipment(params)
            .then(res => {
                const response = res.data;

                if (response.statusCode == 1) {
                    const data = response.responseData;
                    fetchGymDetails();
                    fetchGymEquipments();
                    toast.success(data.message);
                } else if (response.statusCode == 0) {
                    toast.error(response.error.errorMessage)
                }
            })
            .catch((error) => {
                console.log('error', error);
            })
    };





    const handlePageChange = (pageNo) => {
        setPageNumber(pageNo);
        fetchGymEquipments(pageNo);
    };

    const renderGymDetails = () => {
        const manager = gymDetails.managers && gymDetails.managers[0];
        return (
            <div>
                <table className="table table-bordered">
                    <tbody>
                        <tr className="align-items-center">
                            <td style={{ width: "200px" }}><p>Gym Name</p></td>
                            <td><p>{gymDetails.name}</p></td>
                        </tr>
                        <tr className="align-items-center">
                            <td><p>Company Name</p></td>
                            <td><p>{gymDetails.companyName}</p></td>
                        </tr>
                        <tr className="align-items-center">
                            <td><p>Gym Type</p></td>
                            <td><p>{gymDetails.type}</p></td>
                        </tr>
                        <tr className="align-items-center">
                            <td><p>Gym Creation Date</p></td>
                            <td><p>{moment(gymDetails.created).format('Do MMM YYYY')}</p></td>
                        </tr>
                        <tr className="align-items-center">
                            <td><p>Address</p></td>
                            <td><p>{gymDetails.address && gymDetails.address.streetAddr}</p></td>
                        </tr>
                        <tr className="align-items-center">
                            <td><p>State</p></td>
                            <td><p>{gymDetails.address && gymDetails.address.state}</p></td>
                        </tr>
                        <tr className="align-items-center">
                            <td><p>City</p></td>
                            <td><p>{gymDetails.address && gymDetails.address.city}</p></td>
                        </tr>
                        <tr className="align-items-center">
                            <td><p>Pincode</p></td>
                            <td><p>{gymDetails.address && gymDetails.address.pincode}</p></td>
                        </tr>
                        <tr className="align-items-center">
                            <td><p>Gym Manager</p></td>
                            <td><p>{manager && manager.firstName}</p></td>
                        </tr>
                        <tr className="align-items-center">
                            <td><p>Gym Manager Contact No.</p></td>
                            <td><p>{manager && manager.mobileNo}</p></td>
                        </tr>
                        <tr className="align-items-center">
                            <td><p>Gym Manager Email</p></td>
                            <td><p>{manager && manager.email}</p></td>
                        </tr>
                        {/* <tr className="align-items-center">
                            <td><p>Total Equipments</p></td>
                            <td><p>{gymDetails.equipmentCount}</p></td>
                        </tr> */}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderCTA = () => {
        return (
            <div className='gym-details-cta'>
                <div>
                    <Button type="primary" onClick={() => setShowAddEquipmentToGymModal(true)}>+ Equipment to Gym</Button>
                </div>

                <div style={{ marginLeft: "40px"}}>
                    <Button type="primary" onClick={() => importHandler()}>Add Equipments in Bulk to Gym</Button>
                </div>

                <div style={{ marginLeft: "40px"}}>
                    <Button type="primary" onClick={() => setShowRequestInstallationModal(true)}>Request Installation</Button>
                </div>
            </div>
        );
    };

    const openRequestSentModal = (gymEqp) => {
        setShowRequestSentModal(true);
        setSelectedEquipmentLogisticsId(gymEqp.installationRequestId);
    };

    const openEditModal = (gymEqp) => {
        setShowEditModal(true);
        setGymEqpToEdit(gymEqp);
    };

    const hideAddEquipmentToGymModal = () => setShowAddEquipmentToGymModal(false);
    const hideRequestInstallationModal = () => setShowRequestInstallationModal(false);
    const hideRequestSentModal = () => setShowRequestSentModal(false);
    const hideEditModal = () => setShowEditModal(false);

    const filteredListForInstallation = gymEquipmentsList.filter((eqp) => !eqp.isEquipmentReadyForInstallation);


    const importHandler = () => {
        setImportExcelPopup(true);
        setValidationError('');
        setValidationSuccess('')
    }

    const closeModal = () => {
        setImportExcelPopup(false);
        setBadEntryErrors([]);
        setValidationSuccess('')
        setIsUploading(false)
    }


    const handleFileDrop = (accepted, rejected) => {
        if (accepted && accepted.length) {
            setRequestsExcelSheet(accepted[0]);
            setValidationError('');
            setValidationSuccess('')
        }

        else if (rejected && rejected.length) {
            setValidationSuccess('');
            setRequestsExcelSheet('');
        }
    }

    const submitValidateExcelUpload = (e) => {
        e.preventDefault();
        if (!requestsExcelSheet) {
            setValidationError('Please select a file!');
        }else {
            let params = new FormData()
            params.append('gymEquipmenstData', requestsExcelSheet);
            params.append('gymId',id);
            setValidationError('');
            setIsUploading(true);

            addEquipmentsFromExcelToGym(params)
                .then(res => {
                    let response = res.data;
                    if (response.statusCode === 1) {
                        setValidationSuccess('file imported successfully');
                        toast.success(response.responseData.message);
                        fetchGymDetails();
                        fetchGymEquipments();
                    } else if (response.statusCode === 0) {
                        setValidationError(response.error.errorMessage);
                        toast.error(response.error.errorMessage);
                    }
                    setIsUploading(false);
                    setImportExcelPopup(false);
                })
        }
    }

    const handleDownloadTemplateClick = () => {
        window.open(AddEquipmentTemplateLink, '_blank'); // Opens in a new tab
    };

    return (
        <div className="main-wrapper" style={{ height: "auto" }}>
            <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/dashboard">Dashboard</Link></li>
                {location.state && location.state.gymsRef ? <li className="breadcrumb-item"><Link to="/gyms">Gyms</Link></li> : <li className="breadcrumb-item"><Link to="/gymManagers">Gym Managers</Link></li>}
                <li className="breadcrumb-item">{gymDetails.name}</li>
            </ol>

            <div className="gym-details-and-cta">
                {renderGymDetails()}
                {renderCTA()}
            </div>
            <div className="table-responsive mb-4" style={{ minHeight: '100px', marginTop: '30px' }}>
                <table className="table table-bordered table-striped text-center">
                    <thead>
                        <tr>
                            {columnDetails.map((item, i) => 
                                <th scope="col" key={i}>{item.fieldName}</th>
                            )}
                        </tr>
                    </thead>

                    <tbody>
                        {gymEquipmentsList.map((gymEqp, index) => {
                            return (
                                <tr key={gymEqp._id}>
                                    <td className="cm_no_wrap">{itemsCountPerPage * (pageNumber-1) + (index + 1)}</td>
                                    <td className="cm_no_wrap"> {gymEqp.serialNo}</td>
                                    <td className="cm_no_wrap">{gymEqp.equipmentDetails && gymEqp.equipmentDetails.model}</td>
                                    <td className="cm_no_wrap">{gymEqp.equipmentDetails && gymEqp.equipmentDetails.brand}</td>
                                    <td className="cm_no_wrap">{gymEqp.equipmentDetails && gymEqp.equipmentDetails.vertical && gymEqp.equipmentDetails.vertical.verticalName}</td>
                                    <td className="cm_no_wrap">{gymEqp.purchaseDate ? moment(gymEqp.purchaseDate).format('Do MMM YYYY') : ""}</td>
                                    <td className="cm_no_wrap">{gymEqp.deliveryDate ? moment(gymEqp.deliveryDate).format('Do MMM YYYY') : ""}</td>
                                    <td className="cm_no_wrap">{gymEqp.installationDate ? moment(gymEqp.installationDate).format('Do MMM YYYY') : ""}</td>
                                    <td className="cm_no_wrap">{gymEqp.warrantyExpiryDate ? moment(gymEqp.warrantyExpiryDate).format('Do MMM YYYY') : ""}</td>
                                    <td className="cm_no_wrap">{gymEqp.warrantyRenewDate ? moment(gymEqp.warrantyRenewDate).format('Do MMM YYYY') : ""}</td>
                                    <td className="cm_no_wrap"> {<Link style={{textDecoration: "underline"}} onClick={() => openRequestSentModal(gymEqp)}>{gymEqp.isEquipmentReadyForInstallation ? "Request Sent" : ""}</Link>}</td>
                                    <td className="cm_no_wrap">
                                        <Button className='m-1' variant="outline-info" onClick={() => openEditModal(gymEqp)}> <i className="fa fa-pencil" /> </Button>
                                        <Button className='m-1' variant="outline-danger" onClick={() => deleteGymEquipmentFn(gymEqp)}> <i className="fa fa-trash" /> </Button>
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>

                <Pagination
                    activePage={pageNumber}
                    itemsCountPerPage={itemsCountPerPage}
                    totalItemsCount={totalGymEqps}
                    pageRangeDisplayed={3}
                    onChange={handlePageChange}
                />
            </div>

            {showAddEquipmentToGymModal && 
                <AddEquipmentToGymModal 
                  showModal={showAddEquipmentToGymModal}
                  hideModal={hideAddEquipmentToGymModal} 
                  gymId={id}
                  fetchGymEquipments={fetchGymEquipments}
                  fetchGymDetails={fetchGymDetails}
                />
            }

            {showRequestInstallationModal &&
                <RequestInstallationModal
                  showModal={showRequestInstallationModal}
                  hideModal={hideRequestInstallationModal}
                  gymId={id}
                  fetchGymEquipments={fetchGymEquipments}
                  gymEquipmentsList={filteredListForInstallation}
                />
            }

            {showRequestSentModal &&
                <RequestSentModal 
                  showModal={showRequestSentModal}
                  hideModal={hideRequestSentModal}
                  equipmentLogisticsId={selectedEquipmentLogisticsId}
                />
            }

            {showEditModal &&
                <EditGymEquipmentModal
                  showModal={showEditModal}
                  hideModal={hideEditModal} 
                  gymEqp={gymEqpToEdit}
                  fetchGymEquipments={fetchGymEquipments}
                />
            }

            <Modal show={importExcelPopup} onHide={closeModal} backdrop="static" centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title><h6>Upload File!</h6></Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    <Dropzone multiple={false}
                              onDrop={(acceptedFiles, rejectedFiles) => handleFileDrop(acceptedFiles, rejectedFiles)}
                              accept='.xlsx, .xls, .csv'>
                        {({getRootProps, getInputProps, isDragActive, acceptedFiles}) => (
                            <section>
                                <div {...getRootProps()} className="dropzone text-center"><input {...getInputProps()} />
                                    <i className="fa fa-cloud-upload"/>
                                    {isDragActive ? <p>Drop it like it's hot!</p> : acceptedFiles.length ?
                                        <p>{acceptedFiles[0].name}</p> : <p>Drag file or click to upload</p>}
                                </div>
                            </section>
                        )}
                    </Dropzone>

                    <div className="row col-md-4">
                        <Button variant="info"  size="sm" onClick={handleDownloadTemplateClick}>Download Template</Button>
                    </div>
                </Modal.Body>


                {validationSuccess && <Alert variant="success"> {validationSuccess} </Alert>}
                {validationError && <Alert variant="danger"> {validationError} </Alert>}
                {badEntryErrors.length > 0 && <Alert variant="danger">
                    {badEntryErrors.map(err => {
                        return (
                            <>
                                <span>Row: {err.rowNumber}, &nbsp;&nbsp; </span>
                            </>
                        )
                    })}
                </Alert>}


                <Modal.Footer>
                    <Button variant="danger" onClick={closeModal} size="sm">Cancel</Button>
                    <Button variant="success" onClick={submitValidateExcelUpload}
                            disabled={validationError || isUploading} size="sm">
                        {isUploading ? <><Spinner animation="border" variant="warning"
                                                  size="sm"/> Uploading..</> : 'Upload'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default GymDetails;